<template>
  <section class="classification py-5" v-if="studioCategories.length > 0">
    <div class="container">
      <div class="_title">
        <h1>{{ homeSetting.studios_categories_title }}</h1>
        <span v-html="homeSetting.studios_categories_description"></span>
      </div>
      <div class="row mt-5" v-if="studioCategories.length > 0">
        <p class="text-end font-weight-bold d-none d-md-block">
          <router-link to="/spaces">
            {{ $t("markets.all") }}
          </router-link>
        </p>
        <div style="direction: rtl;text-align: center;text-align: -webkit-center;width: auto;margin: 0 auto;">
          <carousel v-bind="options">
            <slide class="slide-item" v-for="catg in studioCategories" :key="catg.id">
              <a @click="openCategory(catg.id, homeSetting.if_show_categories_filter)">
                <div class="category_item">
                  <div class="card custom-section-div">
                    <img class="card-img" :src="catg.image_url" alt="classification"/>
                    <div class="card-img-overlay">
                      <div class="row">
                        <div class="col-12">
                          <p class="card-title text-center text-sm-end">{{ catg.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </slide>
          </carousel>
        </div>

        <div class="text-center mt-8 d-block d-md-none">
          <router-link to="/spaces" class="btn btn-outline-dark slider-btn">
            {{ $t("display-more") }}
          </router-link>
        </div>
      </div>
      <div v-else class="row">
        <v-col cols="12" md="6">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </div>

      <v-dialog v-model="dialog" width="600px" class="modal_auth">
        <v-card class="dialog_auth" style="height: 500px;">
          <v-container class="px-8">
            <div class="text-end mb-8">
              <a href="javascript:void(0);" @click.prevent="dialog = false">
                <i class="mdi mdi-close mdi-18px"></i>
              </a>
            </div>
            <div class="filter_projects text-start">
              <template>
                <div class="d-flex justify-content-between mb-2">
                  <label>{{ $t('filterProject.city') }}</label>
                  <button @click="toggleCloseMenu" class="btn btn-dark px-8" v-if="chooseDoneShow">
                    <i class="mdi mdi-check-all"></i>&nbsp;&nbsp;{{ $t('choose-done') }}
                  </button>
                </div>
              </template>
              <v-select
                  :items="cities"
                  :item-text="(sec) => sec.name"
                  :item-value="(sec) => sec.id"
                  v-model="filter.citiesVal"
                  multiple filled solo :label="$t('filterProject.city')" prepend-inner-slot
                  :menu-props="{closeOnClick: closeOnClick,closeOnContentClick: closeOnClick}"
                  :no-data-text="$t('no-data')"
                  @click="chooseDoneShow = true;">
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggleCheckAll">
                    <v-list-item-action>
                      <v-icon>{{ icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('choose-all') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>

              <label class="mb-2">{{ $t('studios.StartDate') }}</label>
              <v-menu :close-on-content-click="true" :nudge-right="0" transition="scale-transition"
                      offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="filter.add_date" :placeholder="$t('choose-date')"
                                readonly filled solo v-bind="attrs"
                                v-on="on" style="border: none;">
                    <template #prepend-inner>
                      <v-icon color="#4D4D4D" style="margin-inline-end: 10px;">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker :locale="lang" v-model="filter.add_date"
                               :min="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>

              <div class="text-center">
                <button @click="search" class="btn btn-dark mt-16 w-50">
                  {{ $t("filterProject.search") }}
                </button>
              </div>
            </div>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  props: ["studioCategories", "homeSetting"],
  data() {
    return {
      dialog: false,
      lang: localStorage.getItem('lang') || 'ar',
      cities: [],
      filter: {
        citiesVal: [],
        categoriesVal: [],
        add_date: '',
      },
      closeOnClick: false,
      chooseDoneShow: false,
      options: {
        loop: true,
        margin: 5,
        perPage: 1,
        center: true,
        autoplay: true,
        autoplayTimeout: 3000,
        paginationEnabled: false,
        autoplayHoverPause: true,
        navigationEnabled: false,
        paginationColor: "#555",
        paginationActiveColor: "#9f762b",
        paginationSize: 5,
        rtl: true,
        perPageCustom: [
          [0, 1],
          [320, 2],
          [425, 2.25],
          [475, 3],
          [768, 4],
          [960, 5],
          [1264, 7],
          [1500, 8],
        ],
      },
    };
  },
  created() {
    axios.get("cities", {})
        .then(({data}) => {
          this.cities = data.data;
        });
  },
  computed: {
    checkAllCities() {
      return this.filter.citiesVal.length === this.cities.length
    },
    checkSomeCities() {
      return this.filter.citiesVal.length > 0 && !this.checkAllCities
    },
    icon() {
      if (this.checkAllCities) return 'mdi-close-box'
      if (this.checkSomeCities) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    openCategory(categoryId, ifShowCategoriesFilter) {
      this.filter.categoriesVal = [categoryId];
      this.dialog = (ifShowCategoriesFilter === 'yes');
      if (ifShowCategoriesFilter === 'no') {
        this.search();
      }
    },
    search() {
      this.$router.push({
        name: 'studios',
        query: this.filter
      });
    },
    toggleCheckAll() {
      this.$nextTick(() => {
        if (this.checkAllCities) {
          this.filter.citiesVal = [];
        } else {
          this.filter.citiesVal = this.cities.map(a => a.id);
        }
      })
    },
    toggleCloseMenu() {
      this.chooseDoneShow = false;
      this.closeOnClick = true;
      setTimeout(() => {
        this.closeOnClick = false;
      }, 100);
    }
  },
};
</script>

<style lang="scss" scoped>
.classification {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 0 30px;
  @media(max-width: 580px) {
    padding: 0 0;
  }

  ._title {
    margin-bottom: 35px;

    h1 {
      color: #111;
      font-weight: 700;
      font-size: 43px;
    }

    span {
      color: #4D4D4D;
      font-size: 23px;
      font-weight: 500;
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 18px;
      }
      span {
        font-size: 16px;
      }
    }
  }

  .custom-section-div {
    color: #fff;
    border: none;
    overflow: hidden;
    width: 150px;
  }

  .custom-section-div:hover {
    width: 300px;
    transition: 1s ease-in-out; /* Animation */
  }

  .custom-section-div .card-img {
    width: 150px;
    height: 450px;
    object-fit: cover;
  }

  .custom-section-div:hover .card-img {
    transform: scale(1.25);
    width: 300px;
    transition: 1s ease-in-out; /* Animation */
  }

  .custom-section-div .card-img-overlay {
    top: auto;
    z-index: 9;
  }

  .custom-section-div .card-img-overlay p {
    font-size: 20px;
    transform: rotateZ(-90deg) translateX(100px) translateY(-40px);
    text-wrap: nowrap;
  }

  .custom-section-div:hover .card-img-overlay p {
    transform: rotateZ(0deg) translateX(0) translateY(0);
    transition: transform 1s; /* Animation */
  }

  .custom-section-div:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.25) 0,
            rgba(17, 17, 17, 0.75) 100%
    );
  }

  .custom-section-div:hover:after {
    transition: 0.5s ease;
    background: linear-gradient(
            180deg,
            rgba(17, 17, 17, 0.25) 0,
            rgba(17, 17, 17, 0.75) 100%
    );
  }

  .category_item {
    margin: 15px 7px 0;
  }

  .slider-btn {
    min-width: 200px;
    border-radius: 50px;
  }

  @media (max-width: 959px) {
    .slider-btn {
      border: 0.5px solid #ACACAC;
      color: #4D4D4D;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      min-width: 110px;
      border-radius: 30px;
      padding: 10px 0;
    }
    .slider-btn:hover {
      color: #fff;
    }
  }
}
</style>
