<template>
  <section class="Discover py-5" v-if="cities.length > 0">
    <div>
      <div class="_title pb-md-8">
        <h1>{{ homeSetting.studios_cities_title }}</h1>
        <span v-html="homeSetting.studios_cities_description"></span>
      </div>
      <div class="row mt-8 mt-sm-16" v-if="cities.length > 0">
        <div style="direction: rtl;">
          <carousel v-bind="options">
            <slide class="slide-item" v-for="city in cities" :key="city.id">
              <a @click="openCity(city.id, homeSetting.if_show_cities_filter)">
                <div class="category_item">
                  <div class="card custom-section-div">
                    <img class="card-img" :src="city.image_url" :alt="city.name"/>
                    <p class="card-title">
                      <span class="font-weight-bold">{{ city.name }}</span>
                      {{ city.spaces_count }} {{ $t('unit') }}
                    </p>
                  </div>
                </div>
              </a>
            </slide>
          </carousel>
        </div>
      </div>
      <div v-else class="row">
        <v-col cols="12" md="3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </div>

      <v-dialog v-model="dialog" width="600px" class="modal_auth">
        <v-card class="dialog_auth" style="height: 500px;">
          <v-container class="px-8">
            <div class="text-end mb-8">
              <a href="javascript:void(0);" @click.prevent="dialog = false">
                <i class="mdi mdi-close mdi-18px"></i>
              </a>
            </div>
            <div class="filter_projects text-start">
              <template>
                <div class="d-flex justify-content-between mb-2">
                  <label>{{ $t('filterProject.category') }}</label>
                  <button @click="toggleCloseMenu" class="btn btn-dark px-8" v-if="chooseDoneShow">
                    <i class="mdi mdi-check-all"></i>&nbsp;&nbsp;{{ $t('choose-done') }}
                  </button>
                </div>
              </template>
              <v-select
                  :items="categories"
                  :item-text="(sec) => sec.name"
                  :item-value="(sec) => sec.id"
                  v-model="filter.categoriesVal"
                  multiple filled solo :label="$t('filterProject.category')" prepend-inner-slot
                  :menu-props="{closeOnClick: closeOnClick,closeOnContentClick: closeOnClick}"
                  :no-data-text="$t('no-data')"
                  @click="chooseDoneShow = true;">
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggleCheckAll">
                    <v-list-item-action>
                      <v-icon>{{ icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('choose-all') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>

              <label class="mb-2">{{ $t('studios.StartDate') }}</label>
              <v-menu :close-on-content-click="true" :nudge-right="0" transition="scale-transition"
                      offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="filter.add_date" :placeholder="$t('choose-date')"
                                readonly filled solo v-bind="attrs"
                                v-on="on" style="border: none;">
                    <template #prepend-inner>
                      <v-icon color="#4D4D4D" style="margin-inline-end: 10px;">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker :locale="lang" v-model="filter.add_date"
                               :min="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>

              <div class="text-center">
                <button @click="search" class="btn btn-dark mt-16 w-50">
                  {{ $t("filterProject.search") }}
                </button>
              </div>
            </div>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  props: ["cities", "homeSetting"],
  data() {
    return {
      dialog: false,
      lang: localStorage.getItem('lang') || 'ar',
      categories: [],
      filter: {
        citiesVal: [],
        categoriesVal: [],
        add_date: '',
      },
      closeOnClick: false,
      chooseDoneShow: false,
      options: {
        loop: true,
        margin: 5,
        perPage: 1,
        center: false,
        autoplay: true,
        autoplayTimeout: 3000,
        paginationEnabled: false,
        autoplayHoverPause: true,
        navigationEnabled: true,
        paginationColor: "#555",
        paginationActiveColor: "#9f762b",
        paginationSize: 5,
        rtl: true,
        perPageCustom: [
          [0, 1],
          [375, 1.5],
          [425, 2],
          [550, 2.5],
          [700, 2.8],
          [1100, 3],
          [1200, 3.5],
          [1400, 4],
          [1700, 5],
          [2100, 6],
          [2600, 7],
        ],
      },
    };
  },
  created() {
    axios.get("categories", {})
        .then(({data}) => {
          this.categories = data.data;
        });
  },
  computed: {
    checkAllCategories() {
      return this.filter.categoriesVal.length === this.categories.length
    },
    checkSomeCategories() {
      return this.filter.categoriesVal.length > 0 && !this.checkAllCategories
    },
    icon() {
      if (this.checkAllCategories) return 'mdi-close-box'
      if (this.checkSomeCategories) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    openCity(cityId, ifShowCitiesFilter) {
      this.filter.citiesVal = [cityId];
      this.dialog = (ifShowCitiesFilter === 'yes');
      if (ifShowCitiesFilter === 'no') {
        this.search();
      }
    },
    search() {
      this.$router.push({
        name: 'studios',
        query: this.filter
      });
    },
    toggleCheckAll() {
      this.$nextTick(() => {
        if (this.checkAllCategories) {
          this.filter.categoriesVal = [];
        } else {
          this.filter.categoriesVal = this.categories.map(a => a.id);
        }
      })
    },
    toggleCloseMenu() {
      this.chooseDoneShow = false;
      this.closeOnClick = true;
      setTimeout(() => {
        this.closeOnClick = false;
      }, 100);
    }
  },
};
</script>

<style scoped lang="scss">
.Discover {
  margin-top: 60px;
  @media (max-width: 580px) {
    padding: 0 0;
  }

  ._title {
    h1 {
      color: #111;
      font-weight: 700;
      font-size: 43px;
    }

    span {
      color: #4D4D4D;
      font-size: 23px;
      font-weight: 500;
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 18px;
      }
      span {
        font-size: 16px;
      }
    }
  }

  .custom-section-div {
    border: none;
    overflow: hidden;
    text-align: center;
    border-radius: 30px;
  }

  .custom-section-div .card-img {
    width: 330px;
    height: 330px;
    object-fit: cover;
    border-radius: 30px;
    transition: transform 0.5s; /* Animation */
    box-shadow: 0 8px 12px 2px #ccc;
    margin: 0 auto;
  }

  .custom-section-div .card-title {
    z-index: 9;
    background: #FFFFFF99;
    border: 3px solid #fff;
    width: 90%;
    border-radius: 50px;
    margin: 15px auto 0 auto;
    padding: 10px 0;
  }

  .custom-section-div .card-title p {
    font-size: 20px;
  }

  .custom-section-div:hover .card-img {
    transform: scale(1.35);
  }

  .custom-section-div:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .custom-section-div:hover:after {
    transition: 0.5s ease;
  }

  .category_item {
    margin: 40px 20px;
  }

  @media (max-width: 767px) {
    .category_item {
      margin: 10px;
    }
    .custom-section-div .card-img {
      width: 100%;
      height: 180px;
    }
    .custom-section-div:hover .card-img {
      transform: scale(1.25);
    }
    .custom-section-div .card-title {
      margin-top: 24px;
      border: 1px solid rgba(172, 172, 172, 0.30);
      background: rgba(255, 255, 255, 0.60);
    }
  }
}

h1 {
  .marker {
    font-weight: bold;
    background: #000;
    color: #fff;
    padding: 10px 10px 10px 14px;
    margin: 0 5px;
  }
}
</style>
