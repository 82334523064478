import axios from 'axios'

const state = {
    signUpMessage: "",
    verifyMessage: "",
    verifyError: "",
    loginMessage: "",
    userData: null,
    statusProvider: ""
}

const getters = {
    GetSignUpMessage: state => state.signUpMessage,
    GetSmsType: state => state.smsType,
    GetVerifyMessage: state => state.verifyMessage,
    GetVerifyError: state => state.verifyError,
    GetLoginMessage: state => state.loginMessage,
    GetUserData: state => state.userData,
    GetstatusProvider: state => state.statusProvider,
}

const mutations = {
    SET_MESSAGE_SIGNUP(state, signUpMessage) {
        state.signUpMessage = signUpMessage
    },
    SET_SMS_TYPE(state, smsType) {
        state.smsType = smsType
    },
    SET_MESSAGE_VERIFY(state, verifyMessage) {
        state.verifyMessage = verifyMessage
    },
    SET_ERROR_VERIFY(state, verifyError) {
        state.verifyError = verifyError
    },
    SET_MEESAGE_LOGIN(state, loginMessage) {
        state.loginMessage = loginMessage
    },
    SET_USER_DATA(state, userData) {
        state.userData = userData
        localStorage.setItem('user', JSON.stringify(userData))
        axios.defaults.headers.common['Authorization'] = `Bearer ${userData.accessToken} `
    },
    SET_CLEAR_DATA() {
        localStorage.clear();
    },
    SETstatusProvider() {
        state.statusProvider = statusProvider
    }
}

const actions = {
    async handleSignUp({commit}, PAYLOAD) {
        return axios.post('register', PAYLOAD).then(res => {
            commit('SET_MESSAGE_SIGNUP', res.data.message);
            commit('SET_SMS_TYPE', res.data.data.sms_type);
        })
    },
    async handleVerifyAcc({commit}, PAYLOAD) {
        return axios.post('register-verify', PAYLOAD).then(res => {
            commit('SET_MESSAGE_VERIFY', res.data.message);
            commit('SET_USER_DATA', res.data.data);
            localStorage.setItem('userImage', res.data.data.image_url);
            localStorage.setItem('lang', res.data.data.lang);
        })
    },
    async handleLogin({commit, dispatch}, PAYLOAD) {
        return axios.post("login", PAYLOAD).then(res => {
            commit('SET_USER_DATA', res.data.data);
            commit('SET_MEESAGE_LOGIN', res.data.message);
            localStorage.setItem('userImage', res.data.data.image_url);
            localStorage.setItem('lang', res.data.data.lang);
        })
    },
    async handleLogout({commit}) {
        commit('SET_CLEAR_DATA')
        window.location.href = '/';
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}