<template>
  <div>
    <div class="auth_navbar">
      <button class="btn btn-dark py-2 rounded-1" @click="openLogin" v-if="show_btn">
        {{ $t("auth.login-register") }}
      </button>
      <button @click="openLogin" v-if="show_text">
        {{ $t("auth.login-register") }}
      </button>
      <v-btn class="auth_modal" @click="openLogin" v-else>
        <v-icon> mdi-account</v-icon>
      </v-btn>
      <v-dialog v-model="dialogAuth" width="600px" class="modal_auth">
        <v-card class="dialog_auth">
          <div class="dialog_title d-flex justify-content-center align-items-center flex-column position-relative">
            <v-card-title class="font-weight-bold">
              {{ $t("auth.login") }}
            </v-card-title>
            <v-card-text color="#868E96" style="color: #868E96;">
              {{ $t("auth.loginCine") }}
            </v-card-text>
            <hr style="margin: 0 !important;color:#868E96"/>

            <div class="position-absolute" style="left: 5%;top: 10px;">
              <a href="javascript:void(0);" @click.prevent="dialogAuth = false;">
                <i class="mdi mdi-close mdi-24px"></i>
              </a>
            </div>
          </div>
          <form>
            <v-container>
              <div>
                <p class="d-flex justify-start items-start mb-3">
                  {{ $t("auth.country") }}
                </p>
                <v-select class="country-select" :items="getCountries" v-model="country_id" item-value="id"
                          :placeholder="$t('auth.choose-country')" :error-messages="countryIdErrors"
                          style="z-index: 9999 !important;">
                  <template v-slot:selection="{ item, index }">
                    <img class="country-img" :src="item.flag_path" :alt="item.name"/>
                    &nbsp;(+{{ item.code }})&nbsp;{{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img class="country-img" :src="item.flag_path" :alt="item.name"/>
                    &nbsp;(+{{ item.code }})&nbsp;{{ item.name }}
                  </template>
                </v-select>
              </div>
              <div>
                <p class="d-flex justify-start items-start">
                  {{ $t("auth.phone") }}
                </p>
                <v-text-field
                    v-model.trim="mobile"
                    :error-messages="mobileErrors"
                    :placeholder="$t('auth.phone')"
                    required outlined
                    @input="$v.mobile.$touch()"
                    @blur="$v.mobile.$touch()"
                    @keydown.space.prevent
                ></v-text-field>
              </div>
              <!--              <div>-->
              <!--                <p class="d-flex justify-start items-start">-->
              <!--                  {{ $t("auth.Password") }}-->
              <!--                </p>-->
              <!--                <v-text-field-->
              <!--                    v-model="password"-->
              <!--                    :error-messages="passowrdErrors"-->
              <!--                    placeholder="********"-->
              <!--                    required-->
              <!--                    :type="hiddenPassword ? 'text' : 'password'"-->
              <!--                    outlined-->
              <!--                    @input="$v.password.$touch()"-->
              <!--                    @blur="$v.password.$touch()"-->
              <!--                >-->
              <!--                  <v-icon-->
              <!--                      v-if="hiddenPassword"-->
              <!--                      slot="append"-->
              <!--                      style="cursor: pointer"-->
              <!--                      @click="hiddenPassword = !hiddenPassword"-->
              <!--                  >mdi-eye-->
              <!--                  </v-icon-->
              <!--                  >-->
              <!--                  <v-icon-->
              <!--                      v-else-->
              <!--                      slot="append"-->
              <!--                      style="cursor: pointer"-->
              <!--                      @click="hiddenPassword = !hiddenPassword"-->
              <!--                  >mdi-eye-off-->
              <!--                  </v-icon-->
              <!--                  >-->
              <!--                </v-text-field>-->
              <!--              </div>-->
              <!--              <div class="d-flex justify-content-between align-items-start md:flex-row __auth_opt">-->
              <!--                <v-checkbox color="#007FED" v-model="checkbox" :label="$t('auth.rememmber')" hide-details></v-checkbox>-->
              <!--                <forgetpassword @update:dialogAuth="dialogAuth = $event" :dialogAuth="dialogAuth"></forgetpassword>-->
              <!--              </div>-->
              <div class="row text-start mt-3 mb-8 gap-3 gap-md-0">
                <div class="col-12 col-sm-6">
                  <input class="form-check-input" type="radio" v-model="verify_type" id="verify-type-sms" value="sms"
                         checked>&nbsp;&nbsp;
                  <label class="form-check-label" for="verify-type-sms">{{ $t('mobile-verify') }}</label>
                </div>
                <div class="col-12 col-sm-6">
                  <input class="form-check-input" type="radio" v-model="verify_type" id="verify-type-whatsapp"
                         value="whatsapp">&nbsp;&nbsp;
                  <label class="form-check-label" for="verify-type-whatsapp">{{ $t('whatsapp-verify') }}</label>
                </div>
              </div>
              <div class="flex justift-start items-start" style="height: 70px;">
                <v-btn
                    class="submit"
                    :loading="loading"
                    @click="HandleLogin"
                    color="#000"
                >
                  {{ $t("auth.login") }}
                </v-btn>
              </div>
              <hr style="width:100%"/>
            </v-container>
          </form>
          <v-spacer></v-spacer>
          <div
              class="p-3 pb-0 d-flex justify-content-center align-items-center flex-column Ques_auth"
          >
            <signup @update:dialogAuth="dialogAuth = $event" :dialogAuth="dialogAuth"></signup>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="verifyAccDialog" width="600px" class="modal_auth">
        <v-card class="dialog_auth">
          <div class="dialog_title d-flex justify-content-center align-items-center flex-column position-relative">
            <v-card-title class=" font-weight-bold">
              {{ $t('auth.account-verify') }}
            </v-card-title>
            <v-card-text class="px-8" v-if="ifSendWhatsapp" color="#6c757d">{{
                $t('auth.add-verify-whatsapp')
              }}
            </v-card-text>
            <v-card-text class="px-8" v-else color="#6c757d">{{ $t('auth.addverifyMoile') }}</v-card-text>
            <hr/>

            <div class="position-absolute" style="left: 5%;top: 10px;">
              <a href="javascript:void(0);" @click.prevent="verifyAccDialog = false;">
                <i class="mdi mdi-close mdi-24px"></i>
              </a>
            </div>
          </div>
          <form>
            <v-container>
              <div>
                <p class="d-flex justify-start items-start">
                  {{ $t("auth.verify") }}
                </p>
                <v-text-field
                    v-model="verify"
                    :placeholder="$t('auth.add-verify')"
                    required
                    outlined
                ></v-text-field>
              </div>
              <div class="flex justift-start items-start mt-4">
                <v-btn
                    class="submit"
                    :loading="loading"
                    @click="HandleVerify"
                    color="#000"
                >
                  {{ $t("auth.submit") }}
                </v-btn>
              </div>
            </v-container>
            <hr style="margin-bottom: 24px !important;width:100%"/>
          </form>
          <v-spacer></v-spacer>
          <div class="mb-3 p-3 d-flex justify-content-center align-items-center flex-column Ques_auth">
            <p>
              {{ $t("auth.problem") }}
              <a :href="'https://wa.me/'+getWhatsappSettings.whatsapp_number" target="_blank">
                {{ $t("auth.contact-us-whatsapp") }}
              </a>
            </p>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {loginMixin} from "@/rules";
import signup from "./signup.vue";
import verifyAcc from "./verify-acc.vue";
// import forgetpassword from "./forgetPass.vue";
import firebase from "firebase/app";
import "firebase/messaging";

export default {
  name: "login",
  mixins: [loginMixin],
  props: {
    'drawer': {},
    'show_btn': {type: Boolean, default: false},
    'show_text': {type: Boolean, default: false},
  },
  components: {
    signup,
    // forgetpassword,
    verifyAcc,
  },
  data() {
    return {
      // drawer: true,
      dialogAuth: false,
      // checkbox: false,
      loading: false,
      verifyAccDialog: false,
      ifSendWhatsapp: false,
      verify: "",
      status: "",
      // hiddenPassword: false,
      fcmToken: null,
      country_id: null,
      verify_type: 'sms',
    };
  },
  async created() {
    await this.retrieveFCMToken();
  },
  computed: {
    ...mapGetters(["GetLoginMessage"]),
    ...mapGetters("home", ["getCountries", "getWhatsappSettings"]),
    loginPAYLOAD() {
      return {
        country_id: this.country_id,
        mobile: this.mobile,
        // password: this.password,
        fcm_token: this.fcmToken,
        verify_type: this.verify_type,
      };
    },
    verifyAccPAYLOAD() {
      const country_id = localStorage.getItem("country_id");
      const mobile = localStorage.getItem("mobile");
      const PAYLOAD = {
        country_id: country_id,
        mobile: mobile,
        code: this.verify,
      };
      return {...PAYLOAD};
    },
  },
  methods: {
    ...mapActions(['handleGetDataProfile']),
    async retrieveFCMToken() {
      try {
        const messaging = firebase.messaging();
        this.fcmToken = await messaging.getToken();
      } catch (error) {
        console.error("FCM Token retrieval error:", error);
      }
    },
    HandleLogin() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      this.retrieveFCMToken().then(() => {
        this.$store
            .dispatch("handleLogin", this.loginPAYLOAD)
            .then((res) => {
              this.handleGetDataProfile()
              this.resetLogin();
              this.loading = false;
              this.TriggerNotify("success", this.GetLoginMessage);
            })
            .catch((e) => {
              this.loading = false;
              // this.TriggerNotify("error", e.response.data.message);
              if (e.response.data.verify === false) {
                this.TriggerNotify("success", e.response.data.message);
                localStorage.setItem("country_id", e.response.data.country_id);
                localStorage.setItem("mobile", e.response.data.mobile);
                this.verifyAccDialog = true;
                this.ifSendWhatsapp = (e.response.data.sms_type === 'whatsapp');
              } else {
                this.TriggerNotify("error", e.response.data.message);
              }
            });
      });
    },
    HandleVerify() {
      this.$v.$touch();
      this.loading = true;
      this.$store
          .dispatch("handleVerifyAcc", this.verifyAccPAYLOAD)
          .then(() => {
            this.loading = false;
            this.resetVerifyAcc();
            this.TriggerNotify("success", this.GetVerifyMessage);
          })
          .catch((e) => {
            this.loading = false;
            this.TriggerNotify("error", e.response.data.message);
            this.TriggerNotify("error", e.response.data.error);
          });
    },
    resetLogin() {
      this.$v.$reset();
      this.country_id = "";
      this.mobile = "";
      // this.password = "";
      this.dialogAuth = false;
      this.verify_type = 'sms';
    },
    resetVerifyAcc() {
      this.verify = false;
      this.verifyAccDialog = false;
      this.$v.$reset();
    },
    openLogin() {
      this.dialogAuth = true;
      this.$emit('update:drawer', false);
    }
  },
};
</script>

<style lang="scss" scoped>
.auth_navbar {
  padding: 10px 0 15px 0;
  margin-inline-start: 10px;
}

.dialog_auth {
  border-radius: 20px !important;

  .v-card__text {
    margin-bottom: 24px;
  }

  form {
    margin-top: 48px;
  }

  .__auth_opt {
    margin-bottom: 36px;
  }

  .v-card__title {
    font-size: 25px !important;
    font-weight: bold !important;
  }
}
</style>

<style>
.country-img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.country-select {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.country-select .v-label {
  left: auto !important;
}

#app .v-menu__content--fixed {
  z-index: 999999999 !important;
}
</style>
