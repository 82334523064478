<template>
  <section class="static-pages">
    <v-container>
      <div class="__bread mt-5">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>
      <div v-if="explanations.length">
        <div class="row mt-3">
          <div class="col-12 col-sm-6 col-md-4" v-for="(explanation, index) in explanations" :key="index">
            <a href="javascript:void(0);" @click.prevent="startAt = index;Openlightbox = true;">
              <template>
                <v-card class="rounded-lg shadow">
                  <div class="position-relative">
                    <v-img class="img-fluid" height="250" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                           :src="explanation.thumb" alt="image" cover></v-img>
                    <i :class="['mdi', 'mdi-48px', 'text-white', 'position-absolute', 'top-50', 'start-50', 'translate-middle',
                      explanation.type === 'image' ? 'mdi-image' : 'mdi-play-circle']"></i>
                  </div>
                  <v-card-title class="file-title" v-html="explanation.caption"></v-card-title>
                </v-card>
              </template>
            </a>
          </div>
        </div>

        <LightBox :media="explanations" v-if="Openlightbox" @onClosed="Openlightbox = false;"
                  :startAt="startAt" :showCaption="true" :showThumbs="false"></LightBox>
      </div>
      <div v-else>
        <NoData/>
      </div>
    </v-container>
  </section>
</template>

<script>
import NoData from "../views/no-data.vue";
import {mapGetters} from "vuex";
import LightBox from 'vue-it-bigger';

import('vue-it-bigger/dist/vue-it-bigger.min.css');

export default {
  components: {
    NoData, LightBox
  },
  data() {
    return {
      Bread: [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t('explanations'),
          disabled: true,
          to: "",
        },
      ],
      Openlightbox: false,
      startAt: 0,
    };
  },
  computed: {
    ...mapGetters("home", [
      "getExplanations",
    ]),
    explanations() {
      return this.getExplanations;
    },
  },
  mounted() {
    this.$store.dispatch("home/fetchSiteData");
  }
};
</script>

<style lang="scss" scoped>
.static-pages {
  height: 100%;
  background-color: #fafafa;
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 70px 0;
  }

  .__bread {
    box-shadow: none !important;

    .v-breadcrumbs {
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      font-size: 14px;
      box-shadow: none !important;
      padding: 12px;
    }

    .v-breadcrumbs__item {
      color: #000 !important;
    }

    .v-breadcrumbs__item--disabled {
      color: #868e96 !important;
    }
  }
}
</style>

<style>
.file-title h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-height: 36px; /* fallback */
  max-height: 36px; /* fallback */
}
</style>
