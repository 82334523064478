<template>
  <div>
    <div class="nav-menu" v-scroll-bg="100">
      <div class="ham_icon">
        <div
            class="d-flex justify-content-between align-items-start flex-row px-5"
        >
          <v-icon @click="showMenu()" class="mt-2" color="#fff">
            mdi-menu
          </v-icon>
          <a href="" style="margin-top: 5px">
            <img
                src="../../public/img/logo.png"
                class="img-fluid mb-2"
                alt="logo"
                style="max-width: fit-content !important"
            />
          </a>
        </div>
      </div>
      <div class="container">
        <div class="nav-content" :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'">
          <div class="logo d-flex justify-content-start align-items-center flex-row" style="gap: 85px">
            <router-link class="logo_org" to="/">
              <img :src="logoSrc" class="img-fluid mb-2 primary_logo logo_org" alt="logo" v-if="logoSrc"/>
              <img src="/img/logo.png" class="img-fluid mb-2 primary_logo" alt="logo" v-else/>
            </router-link>
            <router-link class="second_logo" to="/">
              <img :src="logoSrc" class="img-fluid mb-2 second_logo" alt="logo" v-if="logoSrc"/>
              <img src="/img/logo.png" class="img-fluid mb-2 second_logo" alt="logo"
                   v-else/>
            </router-link>
            <router-link to="/" class="scroll_logo">
              <img :src="siteData.white_logo_url" class="img-fluid mb-2" alt="logo"
                   style="max-width: fit-content !important" width="80" height="70"/>
            </router-link>
          </div>
          <ul
              class="nav-items d-flex justify-content-center align-items-center"
          >
            <li @click="showMenu()">
              <router-link to="/"> {{ $t("navbar.home") }}</router-link>
            </li>
            <li @click="showMenu()">
              <router-link to="/spaces">
                {{ $t("footer.studio") }}
              </router-link>
            </li>
            <li>
              <router-link to="/be-partner-with-us">
                {{ $t('footer.be-partner-with-us') }}
              </router-link>
            </li>
            <li>
              <a :href="our_news_url">
                {{ $t('footer.our-news') }}
              </a>
            </li>
            <li v-if="GetUserData">
              <router-link to="/orders" @click="showMenu()">
                {{ $t('my-orders') }}
              </router-link>
            </li>
            <li v-if="GetUserData">
              <router-link to="/favourite" @click="showMenu()">
                {{ $t('favorite') }}
              </router-link>
            </li>
          </ul>
          <div class="login_btn">
            <div class="optionbox mt-0">
              <v-menu bottom class="User_avatar" rounded offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" v-if="lang === 'ar'" class="mx-5">
                    العربية
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                  <v-btn icon v-on="on" v-if="lang === 'en'" class="mx-5">
                    English
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-list-item-content class="justify-center user_avatar">
                    <div class="mx-auto text-center">
                      <a href="javascript:void(0);" @click="toggleLanguage('ar')">
                        <img src="../../public/img/ksa.webp" alt="ksa" width="20" height="15"/>
                        العربية
                      </a>
                      <v-divider class="my-3"></v-divider>
                      <a href="javascript:void(0);" @click="toggleLanguage('en')">
                        <img src="../../public/img/usa.jpg" alt="usa" width="20" height="15"/>
                        English
                      </a>
                    </div>
                  </v-list-item-content>
                </v-card>
              </v-menu>
            </div>
            <div class="position-relative notification_img">
              <v-btn class="icons_btn" text v-if="GetUserData">
                <img
                    src="../../public/img/notificationswhite.svg"
                    alt="notification"
                    width="25"
                    height="25"
                />
                <div
                    class="notification_dd"
                    v-if="getSidebarNotifications.length > 0"
                >
                  <ul class="notification_ul pl-0">
                    <h6>{{ $t("footer.notifications") }}</h6>
                    <li
                        v-for="(noti, index) in getSidebarNotifications"
                        :key="index"
                        class="d-flex justify-content-between align-items-center flex-row w-100"
                    >
                      <router-link
                          :to="'/' + noti.url"
                          class="d-flex justify-content-between align-items-start flex-row w-100"
                      >
                        <span class="content"> {{ noti.body }} </span>
                        <span class="time"> {{ noti.humantime }} </span>
                      </router-link>
                    </li>
                  </ul>
                  <router-link
                      to="/notifications"
                      class="view_more"
                      color="#000"
                  >
                    {{ $t("stocks.all") }}
                  </router-link>
                </div>
                <div class="notification_dd" v-else>
                  <NoData/>
                </div>
              </v-btn>
            </div>
            <div class="position-relative notification_img">
              <v-btn class="icons_btn" text v-if="GetUserData">
                <router-link to="/chats">
                  <img
                      src="../../public/img/chatdotswhite.svg"
                      alt="notification"
                      width="25"
                      height="25"
                  />
                </router-link>
              </v-btn>
            </div>
            <dialogauth v-if="!GetUserData" :show_text="true" @click="showMenu()"></dialogauth>
            <v-menu
                bottom
                min-width="200px"
                class="User_avatar"
                rounded
                offset-y
                v-if="GetUserData"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon x-large v-on="on">
                  <v-avatar size="40">
                    <img :src="userImage" alt="user" class="user_avatar_img"/>
                  </v-avatar>
                </v-btn>
              </template>
              <v-card>
                <v-list-item-content class="justify-center user_avatar">
                  <div class="mx-auto text-center">
                    <router-link to="/profile" depressed rounded text
                                 @click="showMenu()">
                      {{ $t('my-profile') }}
                    </router-link>
                    <v-divider class="my-3"></v-divider>
                    <router-link to="/orders" @click="showMenu()">
                      {{ $t('my-orders') }}
                    </router-link>
                    <v-divider class="my-3"></v-divider>
                    <router-link to="/favourite" depressed rounded text
                                 @click="showMenu()">{{ $t('favorite') }}
                    </router-link>
                    <v-divider class="my-3"></v-divider>
                    <router-link to="/notifications" depressed rounded text
                                 @click="showMenu()">{{ $t("footer.notifications") }}
                    </router-link>
                    <v-divider class="my-3"></v-divider>
                    <router-link to="/chats" depressed rounded text
                                 @click="showMenu()">{{ $t('chats') }}
                    </router-link>
                    <v-divider class="my-3"></v-divider>
                    <router-link to="/explanations" depressed rounded text
                                 @click="showMenu()">{{ $t('explanations') }}
                    </router-link>
                    <v-divider class="my-3"></v-divider>
                    <a href="javascript:void(0);" v-if="GetUserData"
                       @click.prevent="handleDashboardRedirect">
                      {{ $t('cpanel') }}
                    </a>
                    <v-divider class="my-3"></v-divider>
                    <v-btn text v-if="GetUserData" @click="handleLogout" class="logOut">
                      {{ $t('logout') }}
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-card>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <v-sheet class="overflow-hidden sidebar_mobile" style="position: relative" color="#000" v-scroll-bg="85">
      <v-container class="fill-height">
        <div class="d-flex justify-content-between align-items-start flex-row h-100 w-100">
          <div class="img_sidebar d-flex justify-content-start align-items-start flex-row w-100">
            <router-link to="/" class="logo_mobile_sidebar">
              <img :src="logoSrc" alt="logo" height="50" width="100" v-if="logoSrc" class="object-fit-contain"/>
              <img src="/img/logo.png" alt="logo" height="50" width="100" v-else class="object-fit-contain"/>
            </router-link>
            <router-link to="/" class="logo_mobile_sidebar_scroll">
              <img :src="siteData.white_logo_url" alt="logo" height="50" width="100" class="object-fit-contain"/>
            </router-link>
          </div>
          <v-btn text color="#000" dark @click.stop="drawer = !drawer" class="mt-2">
            <v-icon color="#fff"> mdi-menu</v-icon>
          </v-btn>
        </div>
      </v-container>

      <v-navigation-drawer v-model="drawer" absolute right temporary class="py-5 px-5 sidebar">
        <div class="d-flex justify-content-between align-items-start flex-column h-100 w-100">
          <div class="img_sidebar d-flex justify-center align-items-center flex-row w-100">
            <router-link to="/">
              <img :src="siteData.white_logo_url" alt="logo" width="100" height="50" class="object-fit-contain"/>
            </router-link>
          </div>
          <ul class="d-flex justify-content-start align-items-center flex-column w-100" style="margin-top: 35px">
            <li class="text-start w-100">
              <router-link to="/"> {{ $t("navbar.home") }}</router-link>
              <hr style="background-color: #f4f4f4; color: #f4f4f4; width: 100%"/>
            </li>
            <li class="text-start w-100">
              <router-link to="/spaces">
                {{ $t("footer.studio") }}
              </router-link>
              <hr style="background-color: #f4f4f4; color: #f4f4f4; width: 100%"/>
            </li>
            <li class="text-start w-100" v-if="GetstatusProvider">
              <a href="javascript:void(0);"
                 @click.prevent="handleDashboardRedirect">
                {{ $t("footer.joinSin") }}
              </a>
              <hr style="background-color: #f4f4f4; color: #f4f4f4; width: 100%"/>
            </li>
            <li class="text-start w-100">
              <router-link to="/be-partner-with-us"> {{ $t("footer.be-partner-with-us") }}</router-link>
              <hr style="background-color: #f4f4f4; color: #f4f4f4; width: 100%"/>
            </li>
            <li class="text-start w-100">
              <a :href="our_news_url">
                {{ $t('footer.our-news') }}
              </a>
              <hr style="background-color: #f4f4f4; color: #f4f4f4; width: 100%"/>
            </li>
            <li class="text-start w-100">
              <router-link to="/about"> {{ $t("footer.about") }}</router-link>
              <hr style="background-color: #f4f4f4; color: #f4f4f4; width: 100%"/>
            </li>
            <li class="text-start w-100">
              <router-link to="/contact-us">
                {{ $t("footer.contact") }}
              </router-link
              >
              <hr style="background-color: #f4f4f4; color: #f4f4f4; width: 100%"/>
            </li>
            <li class="text-start w-100" v-if="GetUserData">
              <router-link to="/notifications">
                {{ $t("footer.notifications") }}
              </router-link>
              <hr style="background-color: #f4f4f4; color: #f4f4f4; width: 100%"/>
            </li>
            <div v-if="GetUserData" class="w-100">
              <v-expansion-panels class="menu_side_bar_res">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t('profile') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                        class="mx-auto text-center d-flex justify-content-start align-items-start flex-column links"
                    >
                      <router-link
                          to="/profile"
                          depressed
                          rounded
                          text
                          @click="showMenu()"
                      >
                        {{ $t('my-profile') }}
                      </router-link>
                      <v-divider class="my-3"></v-divider>
                      <router-link to="/orders" @click="showMenu()">
                        {{ $t('my-orders') }}
                      </router-link>
                      <v-divider class="my-3"></v-divider>
                      <router-link to="/favourite" depressed rounded text @click="showMenu()">
                        {{ $t('favorite') }}
                      </router-link>
                      <v-divider class="my-3"></v-divider>
                      <router-link to="/chats" depressed rounded text @click="showMenu()">
                        {{ $t('chats') }}
                      </router-link>
                      <v-divider class="my-3"></v-divider>
                      <router-link to="/explanations" depressed rounded text @click="showMenu()">
                        {{ $t('explanations') }}
                      </router-link>
                      <v-divider class="my-3"></v-divider>
                      <a href="javascript:void(0);"
                         v-if="GetUserData"
                         @click.prevent="handleDashboardRedirect">
                        {{ $t('cpanel') }}
                      </a>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <hr style="background-color: #f4f4f4; color: #f4f4f4; width: 100%"/>
            </div>

            <div class="w-100">
              <v-expansion-panels class="menu_side_bar_res">
                <v-expansion-panel>
                  <v-expansion-panel-header style="text-align: start;">
                    <span v-if="lang === 'ar'">
                      <img src="../../public/img/ksa.webp" alt="ksa" width="20" height="15"/>
                      العربية
                    </span>
                    <span v-if="lang === 'en'">
                      <img src="../../public/img/usa.jpg" alt="usa" width="20" height="15"/>
                      English
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="mx-auto text-center d-flex justify-content-start align-items-start flex-column links">
                      <a href="javascript:void(0);" @click="toggleLanguage('ar')">
                        <img src="../../public/img/ksa.webp" alt="ksa" width="20" height="15"/>
                        العربية
                      </a>
                      <v-divider class="my-3"></v-divider>
                      <a href="javascript:void(0);" @click="toggleLanguage('en')">
                        <img src="../../public/img/usa.jpg" alt="usa" width="20" height="15"/>
                        English
                      </a>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </ul>
          <div
              class="logout_btn w-100"
              v-if="GetUserData"
              @click="this.drawer = false"
          >
            <v-btn class="" color="red" block @click="handleLogout">
              {{ $t('logout') }}
              <v-icon class="mx-2"> mdi-logout</v-icon>
            </v-btn>
          </div>
          <div v-if="!GetUserData" class="w-100 d-flex justify-content-center align-content-center flex-row">
            <dialogauth @update:drawer="drawer = $event" :drawer="drawer"
                        block class="w-100 m-0"></dialogauth>
          </div>
        </div>
      </v-navigation-drawer>
    </v-sheet>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import dialogauth from "@/components/auth/login.vue";
import NoData from "./no-data.vue";
import axios from "axios";

export default {
  components: {
    dialogauth,
    NoData,
  },
  directives: {
    scrollBg: {
      bind(el, binding) {
        window.addEventListener("scroll", function () {
          if (window.pageYOffset > binding.value) {
            el.classList.add("navbar-bg");
          } else {
            el.classList.remove("navbar-bg");
          }
        });
      },
      unbind(el) {
        window.removeEventListener("scroll", () => {
        });
      },
    },
  },
  data() {
    return {
      drawer: false,
      showMobileMenu: false,
      lang: localStorage.getItem("lang") || "ar", // Default to 'ar
      statusProvider: "",
      isSecondSpecialRoute: false,
      isNotificationVisible: false,
      login_url: (process.env.NODE_ENV === 'development') ? 'http://127.0.0.1:8000/provider/login' : (['seenpark.sa', 'www.seenpark.sa'].includes(window.location.hostname)) ? 'https://dashboard.seenpark.sa/provider/login' : 'https://sinback.bb4itdev.com/provider/login',
      our_news_url: ((process.env.NODE_ENV === 'development') ? 'http://127.0.0.1:9000' : ((['seenpark.sa', 'www.seenpark.sa'].includes(window.location.hostname)) ? 'https://blog.seenpark.sa' : 'https://seenblog.bb4itdev.com')) + '/' + (localStorage.getItem("lang") || "ar"),
    };
  },
  computed: {
    ...mapGetters([
      "GetUserData",
      "getSidebarNotifications",
      "GetDataProfile",
      "GetstatusProvider",
      "getImag",
    ]),
    logoSrc() {
      // const path = this.$route.path;
      // const sectionsRegex = /^\/sections\/\d+$/;
      // if (
      //     path === "/" ||
      //     path === "/cine_stocks" ||
      //     // path === "/spaces" ||
      //     path === "/professional_markets" ||
      //     path === "/cine_academies" ||
      //     path === "/camera_gear_rentals" ||
      //     path === "/camera_gear_purchases" ||
      //     path === "/sections" ||
      //     sectionsRegex.test(path)
      // ) {
      //   return this.siteData.white_logo_url;
      // } else {
      //   return this.siteData.black_logo_url;
      // }
      return this.siteData.white_logo_url;
    },
    ...mapGetters("home", ["getSections", "getServices", "getSiteData", 'getSeoSettings']),
    sections() {
      return this.getSections;
    },
    services() {
      return this.getServices;
    },
    siteData() {
      return this.getSiteData;
    },
    userImage() {
      return localStorage.getItem("userImage");
    },
    getJoinCine() {
      return localStorage.getItem("joinCine");
    },
  },
  async created() {
    await this.$store.dispatch("home/fetchSiteData");
    if (this.GetUserData) {
      await this.GetSidebarNotifications();
    }
  },
  mounted() {
    // Check if the class exists in the body
    this.isSecondSpecialRoute = document.body.classList.contains("secondSpecialRoute");
  },
  methods: {
    // showNoti() {
    //   this.isNotificationVisible = !this.isNotificationVisible;
    // },
    showNoti() {
      this.isNotificationVisible = !this.isNotificationVisible;
      if (this.isNotificationVisible) {
        // Add a click event listener to the document to close the notification when clicking outside
        document.addEventListener(
            "click",
            this.closeNotificationOnOutsideClick
        );
      } else {
        // Remove the click event listener when the notification is hidden
        document.removeEventListener(
            "click",
            this.closeNotificationOnOutsideClick
        );
      }
    },
    closeNotificationOnOutsideClick(event) {
      // Check if the clicked element is not within the notification_dd element
      if (!this.$refs.notification.contains(event.target)) {
        this.isNotificationVisible = false;
        document.removeEventListener(
            "click",
            this.closeNotificationOnOutsideClick
        );
      }
    },
    ...mapActions(["GetSidebarNotifications"]),
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    handleDashboardRedirect() {
      return axios.post("redirect-to-dashboard").then(res => {
        window.location.href = this.login_url + '?auth_token=' + res.data.data;
      }).catch(() => {
        window.location.href = this.login_url;
      })
    },
    handleLogout() {
      this.$store.dispatch("handleLogout");
      this.$router.push("/");
    },
    async toggleLanguage(changedLang = 'ar') {
      if (this.GetUserData) {
        await this.$store.dispatch("changeLang", {lang: changedLang});
      }

      this.lang = changedLang;
      localStorage.setItem("lang", changedLang);
      document.body.className = changedLang === "en" ? "lang-en" : "";

      const routerLang = changedLang === 'ar' ? '' : '/en';
      window.location.href = window.location.origin + routerLang + window.location.pathname.replace("/en", "");
    },
    refreshPage() {
      this.$router.go(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.optionbox select {
  background: #000;
  color: var(--primary-color);
  padding: 10px;
  width: 95px;
  height: 50px;
  border: none;
  font-size: 20px;
  /* box-shadow: 0 5px 48px rgb(93, 15, 9); */
  outline: none;
  border: 0 !important;
  padding: 10px 0px;
}

.optionbox:before {
  font-family: "Font Awesome 5 free";
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 30px;
  pointer-events: none;
}

@media (max-width: 1270px) {
  .nav-menu .nav-content {
    gap: 33px;
    padding: 0 0 10px 0px;
  }
  .nav-menu .nav-items li {
    padding: 0 0px;
  }
  .nav-menu .nav-items li a {
    font-size: 13px;
  }
  .nav-menu .nav-items li button#dropdown-1__BV_toggle_ {
    font-size: 14px;
  }
  .optionbox select {
    font-size: 18px;
    width: 80px;
  }
}

.user_avatar {
  hr {
    margin: 0 !important;
  }

  .v-btn {
    height: 36px;
    min-width: 100% !important;
    padding: 0 16px;
    color: #000 !important;
    letter-spacing: 0 !important;
  }

  .logOut {
    color: red !important;
    background-color: transparent !important;
  }

  h3 {
    font-size: 20px !important;
    color: #000 !important;
  }

  p {
    font-size: 14px !important;
  }

  a {
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
    width: 100%;
    display: block;
    text-align: center;
    line-height: 35px;
  }
}

.cart_badge {
  .v-badge--overlap .v-badge__badge {
    top: -25px !important;
    right: -13px !important;
    font-size: 14px;
  }

  .primary {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

@media (max-width: 991px) {
  .dropdown-menu .dropdown-item a {
    color: #000 !important;
  }
  .closed-menu .logo,
  .closed-menu .login_btn {
    display: none !important;
  }
}

.notification_dd {
  position: absolute;
  top: 40px;
  left: -60px;
  user-select: none;
  background: #fff;
  width: 550px;
  height: auto;
  border-radius: 3px;
  display: none;
  box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.125),
  -10px -10px 35px rgba(0, 0, 0, 0.125);
  z-index: 999;
  border-radius: 9px;
  z-index: 999;
  padding: 15px;

  &:before {
    content: "";
    position: absolute;
    top: -19px;
    left: 65px;
    border: 10px solid;
    border-color: transparent transparent #fff transparent;
  }

  h6 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: start;
  }

  ul {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
  }

  li {
    // margin-bottom: 15px;
    padding: 15px 15px;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #cccccc3d;

    span {
      color: #212121;

      &.content {
        width: calc(100% - 100px);
        white-space: pre-wrap;
        text-align: start;
        letter-spacing: 0;
        font-size: 16px;
      }

      &.time {
        max-width: 100px;
      }
    }

    span:last-of-type {
      color: #0000008a;
    }

    &:hover {
      background-color: #00589414;
    }
  }

  .view_more {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: bold;
    text-decoration: underline !important;
  }
}

.icons_btn {
  &:hover {
    .notification_dd {
      display: block !important;
    }
  }
}

.nav-menu {
  @media (max-width: 991px) {
    display: none;
  }
}

.sidebar_mobile {
  background-color: #000 !important;
  color: #fff !important;
  position: absolute !important;
  top: 0;
  height: 80px;
  right: 0;
  width: 100%;
  z-index: 99;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }

  .sidebar {
    ul {
      li {
        a {
          color: #fff;
        }
      }
    }

    .logout_btn {
      button {
        color: #fff;
        letter-spacing: 0;
      }
    }
  }
}

.user_avatar_img {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  object-fit: unset;
}

.v-avatar {
  border-radius: 0;
}

.primary_logo {
  width: 105px;
  height: 70px;
  object-fit: contain;
}

.second_logo {
  width: 105px;
  height: 70px;
  object-fit: contain;
}

.scroll_logo {
  img {
    width: 105px;
    height: 70px;
  }
}

.logo_mobile_sidebar {
  display: block;
}

.logo_mobile_sidebar_scroll {
  display: none;
}

.secondSpecialRoute {
  .sidebar_mobile {
    .v-btn__content {
      i {
        color: #fff !important;
      }
    }

    &.navbar-bg {
      .v-btn__content {
        i {
          color: #fff !important;
        }
      }

      .logo_mobile_sidebar {
        display: none;
      }

      .logo_mobile_sidebar_scroll {
        display: block;
      }
    }
  }
}

.sidebar_mobile.navbar-bg {
  background: #000 !important;
  position: fixed !important;
  top: 0;
  height: 92px;
  z-index: 9999;
  padding-top: 0 !important;
  @media (max-width: 991px) {
    height: auto;
  }
}

.nav-items {
  li {
    position: relative;

    a {
      position: relative;

      &:after {
        content: " ";
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform-origin: 50% 50%;
        width: 50%;
        height: 2px;
        background-color: #fff;
        transition: 0.4s ease-in-out;
        top: 30px;
        transform: translate(-50%) scaleX(0);
        border-radius: 6px;
      }

      &.router-link-exact-active:after {
        transform: translate(-50%) scaleX(1) !important;
      }

      &:hover:after {
        transform: translate(-50%) scaleX(1) !important;
      }
    }
  }
}

.secondSpecialRoute {
  a {
    &:after {
      background-color: #000 !important;
    }
  }
}

.navbar-bg {
  a {
    &:after {
      background-color: #fff !important;
    }
  }
}
</style>